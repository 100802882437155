.container {
    margin:0;
    padding:0;
    background-color:#FFFFFF;
   /* color: #fff;*/
   font-family: Manrope, Helvetica, Arial;
    min-height: 80vh;
    align-items: center;

    position: static;
    padding-top: 10px;
    padding-left: 0rem;
    padding-right: 0rem;
}

.products_section{
   background-color: #FFFFFF;
    
}

.H1{
    text-align: center;
    font-size:30px;
    font-family: Manrope, Helvetica, Arial;
    font-weight: bold;
    margin-bottom: 20px;
}

.products{
    
    padding-top: 0px;
    padding-bottom: 30px;
}

.grid{
    background: #f56f3a;
    color: white;
    text-align: center;
    border-right: 1px solid white;
    padding: 5px 0px;
}

.grid_left{
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
}

.grid_right{
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
    border-right: 0px solid;
}

.grid_list{
    text-align: center;
}

.grid_list_del{
    padding-left: 15px;
}

.demo_infinite_container {
    width: 100%;
    height: 250px;
    padding: 8px 10px;
    overflow: auto;
    
    border-radius: 4px;
  }

  .form{
      padding-top: 20px;
     
  }

  .label div{
    text-align: left;
  }

  .modalCancelProd{
      padding: 10px 10px;
  }

@media screen and (max-width:906px ) {
    .container {

        padding-top: 10px;

    }
}