.container {
    margin: 0;
    padding: 0;
    background-color: #FFFFFA;
    font-family: Manrope, Helvetica, Arial;
    min-height: 80vh;
    align-items: center;
    padding-top: 0px;
    padding-left: 0rem;
    padding-right: 0rem;
}

.section {
    padding-top: 1px;
    padding-right: 5px;
    padding-left: 5px;
    padding-bottom: 5px;
}

.slider {
    border-radius: 5px;
    overflow-x: hidden;
}

.slider_container {
    display: flex;
    transform: translateX(0);
    animation: slider 20s infinite;
}

.image_back{

    padding-bottom: 10rem;
    background-image: url('../../images/Grandients/blue-orange-gradient.svg');
    background-size: cover;
    background-position: center;
}

.Slider1 {
    background-image: url('../../images/Slider1.jpg');
}

.Slider2 {
    background-image: url('../../images/Slider2.jpg');
}

.Slider3 {
    background-image: url('../../images/Slider3.jpg');
}

.Slider4 {
    background-image: url('../../images/Slider4.jpg');
}


.image_container {
    width: 100%;
    padding-top: 35%;
    background-size: cover;
    background-position: center;
    flex-shrink: 0;
}

.div_float_button{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.float_button {
    width: 70%;
    position: absolute;
    text-align: center;
    color: #fff;
    font-size: 20px;
    font-weight: 900;
    line-height: 1.8rem;
    background-color: #f56f3a;
    border: 1px solid #f56f3a;
    border-radius: 50px;
    padding-top: 3px;
    padding-bottom: 3px;
}

@media screen and (max-width:906px) {
    .image_container {
        padding-top: 40%;
    }
}

@media screen and (max-width:756px) {
    .image_container {
        padding-top: 45%;
    }
}

@media screen and (max-width:667px) {
    .image_container {
        padding-top: 50%;
    }
}

@media screen and (max-width:578px) {
    .image_container {
        padding-top: 55%;
    }
}

@media screen and (max-width:489px) {
    .image_container {
        /*padding-top: 60%;*/
        padding-top: 40%;
    }
}

@media screen and (max-width:400px) {
    .image_container {
        /*padding-top: 65%;*/
        padding-top: 45%;
    }
}

@keyframes slider {
    0% {
        transform: translateX(0);
    }
    20% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-100%);
    }
    45% {
        transform: translateX(-100%);
    }
    50% {
        transform: translateX(-200%);
    }
    70% {
        transform: translateX(-200%);
    }
    75% {
        transform: translateX(-300%);
    }
    95% {
        transform: translateX(-300%);
    }
    100% {
        transform: translateX(0);
    }
}