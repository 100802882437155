.container {
    margin: 0;
    padding: 0;
    /* background-color: #FFFFFA; */
    font-family: Arial, Helvetica, sans-serif;
    /* min-height: 100vh; */
    height: 100vh;
    align-items: center;
    /* padding-top: 0px;
    padding-left: 0rem;
    padding-right: 0rem; */

    background: url('../../images/bbackground_original.jpg') no-repeat;
    background-size: cover;
    /* background-size: auto 100%; */
    background-position: center;
}

.inicio {
    margin: 0;
    padding: 0 9%;
    /* padding-top: 2rem;
    padding-bottom: .3rem;  */
}

@font-face {
    font-family: Manrope;
    src: url('../../fonts/Manrope-VariableFont_wght.ttf');
}

.h2{
    color: #fff;
    text-align:center;
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 4rem;
    font-family: Manrope, Helvetica , Arial;
    margin-bottom: 20px;
}

.h3{
    text-align: center;
    color: #fff;
    font-size: 1.4rem;
    font-family: Manrope;
    font-weight: 600;
    line-height: 1.7rem;
    margin-bottom: 1.8rem;
}

.imagen{
    align-items: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.inicio_img {
    width: 60vh;
    animation: float 3s linear infinite;
}

.btn{
    text-decoration: none;
    color: #fff;
    display: block;
    border: 1px solid transparent;
    background-color:  #f56f3a;
    border-radius: 1.75rem;
    text-align: center;
    padding: 5px 10px;
    margin: 5px 10px;
    transition: background-color 0.4s ease-in, color 0.4s ease-in;
}

.btn:hover {
    background-color: #424949;
    border-color: #424949;
    color: #FFFFFA;
}


@keyframes float {
    0%, 100% {
        transform: translateY(0rem);
    }
    50% {
        transform: translateY(-.7rem);
    }
}

@media screen and (min-width:756px ) {
    .inicio {
        padding-top: 7rem;
        padding-bottom: 7rem;
    }
}

@media screen and (max-width:756px ) {
    .inicio {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
}

@media screen and (max-width:667px ) {
	.inicio {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
}

@media screen and (max-width:578px ) {
	.inicio {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
}

@media screen and (max-width:489px ) {
	.inicio {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}

@media screen and (max-width:410px ) {
	.inicio {
        padding-top: 2rem;
        padding-bottom: .3rem;
    }
}