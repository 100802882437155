.container {
    margin:0;
    padding:0;
    background-color:#FFFFFF;
   /* color: #fff;*/
   font-family: Manrope, Helvetica, Arial;
    min-height: 80vh;
    align-items: center;

    position: static;
    padding-top: 10px;
    padding-left: 0rem;
    padding-right: 0rem;
}

.order_section{
   background-color: #FFFFFF;
}

.order_card{
    background-color: #252932;
    border-radius: 15px;
    height: 100%;
    color: #fff;
    padding: 0px 20px;
    padding-bottom: 20px;
}

.title{
    text-align: center;
    font-size:30px;
    font-family: Manrope, Helvetica, Arial;
    font-weight: bold;
    padding: 10px 10px;
    padding-top: 20px;
    margin-bottom: 10px;
    color: #fff;
}

.sub_title{
    color: #fff;
    padding: 10px 10px;
    padding-top: 30px;
    font-weight: bold;
}

.cont_estatus{
    display: block;
    text-align: center;
    padding-right: 0px;
    margin: 0px 0px;
    border: #fff 2px solid;
    border-radius: 30px ;
}

.estatus{
    color: #fff;
    padding: 0px 0px;
    margin: 0px 0px;
    font-weight: bold;
}

.grid_list{
    text-align: center;
    padding: 20px 0px;
    padding-bottom: 0px;
}

.grid_list_right{
    padding: 20px 0px;
    padding-left: 10px;
    padding-bottom: 0px;
}

/*.grid_list_right2{
    padding: 20px 0px;
    padding-left: 10px;
    padding-bottom: 0px;
}*/

.total{
    color: #fff;
    font-weight: bold;
    font-size:20px;
}

.estatus_prod{
    text-align: center;
    border-radius: 15px;
    font-weight: bold;
}

.button_cont{
    padding: 10px;
   
}

.button{
    border-radius: 15px;
    color: #fff;
    background-color: #f56f3a;
    border: #f56f3a;
    overflow: hidden;
}

.button:hover{
    color: #252932;
    background-color: #fff;
}

.buttonCancelProd{
    border-radius: 15px;
    color: #fff;
    background-color: #f56f3a;
    border: #f56f3a;
    position:"absolute";
    padding: 0px 25px;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
}

.buttonCancelProd:hover{
    background-color: #fff;
    border: #f56f3a 1px solid;
    color: #f56f3a;
}

.cancelOrder{
    padding-Top: 15px;
    background-color: #f56f3a;
    text-align: center;
    border-radius: 27PX;
    border-right: 0px solid;
    position: relative;
    color: #fff;
    font-size:15px;
    font-family: Manrope, Helvetica, Arial;
    font-weight: bold;
}

.h3{
    text-align: center;
    font-size: 1.5rem;
    font-weight: 900;
    line-height: 3rem;
    font-family: Manrope, Helvetica, Arial;
    margin-bottom: 10px;
}
.h4{
    text-align: center;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2rem;
    font-family: Manrope, Helvetica, Arial;
    margin-bottom: 10px;
}
/*.p {
    text-align: center;
    font-size: 1.1rem;
    line-height: 1.7rem;
    margin-bottom: 0px;
    margin-left: 15px;
    margin-right: 15px;
    font-weight: 600;
}*/

.p_bold {
    font-size: 18px;
    font-weight: bold; 
}

.colBtn{
    padding-top: 10px;
}

.btn_desc{
    width: 100%;
    border-radius: 15px;
    color: #fff;
    background-color: #f56f3a;
    border: #f56f3a;
    text-align: center;
    overflow: hidden;
    padding-top: 3px;
    padding-bottom: 3px;
}

.info{
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    align-items: center;
    font-family: Manrope, Helvetica, Arial;
}

.border{
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.border_l{
    border-left: 0px;
}

.border_r{
    border-right: 0px;
}