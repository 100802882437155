.container {
    margin: 0;
    padding: 0;
    background-color: #FFFFFF;
    /* color: #fff;*/
    /*font-family: Arial, Helvetica, sans-serif;*/
    font-family: Manrope, Helvetica, Arial;
    min-height: 80vh;
    align-items: center;
    padding-left: 0rem;
    padding-right: 0rem;
}

.content {
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.card {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 15px 15px 1px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.card::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 270px;
    top: 0;
    left: 0;
    /*background-image: linear-gradient(to top, #00f2fe, #4facfe);*/
    background-image: linear-gradient(to top, #ffdd00, #ff4e00);
    clip-path: circle(400px at 50% -48.5%);
}

.header {
    position: relative;
    height: 265px;
}

.main {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    background: url('../../images/CantaritoGrandeBack.jpg') no-repeat center / cover;
    border: solid 4px #ffdd00;
    margin-bottom: 2px;
}

.hover {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(79, 172, 254, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    transition: .5s;
    opacity: 0;
}

image:hover {
    opacity: 1;
}

.username {
    font-size: 2.5rem;
    color: #fff;
    font-weight: 700;
    line-height: 1;
    margin: 5px 0;
}

.rol {
    font-size: 1.5rem;
    color: #fff;
    font-weight: 600;
    opacity: .8;
    color: #fff;
}

.body {
    display: flex;
    justify-content: center;
    padding: 1.7rem 2.5rem 2.6rem 2.5rem;
}

.title {
    position: relative;
    color: #555;
    font-weight: 500;
    font-size: 1.1rem;
    padding: 0 0 3px 0;
    margin-bottom: .9rem;
    display: inline-block;
}

.title::after {
    content: '';
    position: absolute;
    height: 3px;
    width: 50%;
    background-color: #555;
    bottom: 0;
    left: 0;
}

.font{
    font-family: Manrope, Helvetica, Arial;
}

.text {
    color: #666;
    font-weight: 300;
    line-height: 1.7rem;
}

.left {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.buttons {
    display: flex;
    margin-top: 5px;
}

.button_home {
    flex: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .5s;
}

.button_logout {
    flex: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .5s;
}

.button_home:hover {
    flex: 5;
}

.button_logout:hover {
    flex: 5;
}

.home {
    padding: 9.6px 0;
    width: 100%;
    border: 1px solid #f56f3a;
    background-color: #f56f3a;
    color: #fff;
    text-align: center;
    text-decoration: none;
    font-size: .7rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 18.1px;
    margin-right: 3px;
}

.home:hover{
    color: #fff;
}

.logout {
    padding: 7.6px 0;
    width: 100%;
    background-color: #fff;
    border: 2px solid #f56f3a;
    color: #f56f3a;
    text-decoration: none;
    text-align: center;
    text-decoration: none;
    font-size: .7rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 18.1px;
    margin-left: 3px;
} 

.logout:hover{
    color: #f56f3a;
}
