.container {
    margin:0;
    padding:0;
    background-color:#FFFFFA;
    color: #fff;
    font-family: Arial, Helvetica, sans-serif;
    min-height: 80vh;
    align-items: center;

    padding-top: 0px;
    padding-left: 0rem;
    padding-right: 0rem;
}

.cardsection {
    display: grid;
    gap: 1.75rem;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}

.card {
    background: #252932;
    padding: 0;
    border-radius: 15px;
    flex-wrap: wrap;
    margin: 0px;
}

.card_cont{
    padding: 15px 15px;
    max-height: 250px;
    min-height: 250px;
}

/*.card*/ .cardheader {
    flex-basis: 100%;
    font-size: 1.5rem;
    text-transform: uppercase;
    padding-left: 10px;
    font-weight: bold;
    
}

/*.card*/ .cardbody {
    flex-basis: 100%;
    margin-top: 1.2rem;
}

/*.card*/ .overflow {
    max-height: 115px;
    min-height: 115px;
    overflow: hidden;
}

/*.card*/ .cardbody p {
    margin: 10px;
}


/*.card*/ .cardbody a {
    text-decoration: none;
    color: #fff;
    display: block;
    /*display: inline-block;  
    border: 1px solid transparent;*/
    border: 1px solid transparent;
    /*background-color: #f56f3a;*/
    background-color:  #424949 /*#4D5656  #626567; #7B7D7D ;  ;   #839192;*/;
    border-radius: 1.75rem;
    text-align: center;
    padding: 5px 10px;
    margin: 10px 10px;
    transition: background-color 0.4s ease-in, color 0.4s ease-in;
}

/*.card*/ .cardbody a:hover {
    /*background-color: #FFFFFA;
    border-color: #FFFFFA;*/
    background-color: #f56f3a;
    border-color: #f56f3a;
    color: #FFFFFA;
}

/*.card*/ .cardfooter {
    flex-basis: 100%;
    align-self: flex-end;
    font-weight: bold;
    text-align: end;
    font-size: 15px;
    padding: 10px 20px;
    border-radius: 20px;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    
}

@media screen and (max-width:815px ) {

    .cardsection {
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
        padding: 10px;
    }

    .container {
        padding-top: 0px;
    }
}