.title{
    text-align: center;
}

.ant-modal-body{
    padding: 0px 24px;
}

.button_cancel{
    border: #252932 1px solid;
    
}

.button_cancel:hover{
    border: #f56f3a 1px solid;
    color: #f56f3a ;
}

.button_ok{
    border: #f56f3a 1px solid;
    background: #f56f3a;
    color:#fff;
}

.button_ok:hover{
    background: #252932;
    color: #fff ;
}