.container {
    margin: 0;
    padding: 0;
    background-color: #FFFFFF;
    /* color: #fff;*/
    /*font-family: Arial, Helvetica, sans-serif;*/
    font-family: Manrope, Helvetica, Arial;
    min-height: 80vh;
    align-items: center;
    position: static;
    padding-top: 10px;
    padding-left: 0rem;
    padding-right: 0rem;
}

.H1 {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
}

.H3 {
    text-align: justify;
    font-weight: bold;
    margin-bottom: 20px;
}

.form {
    padding-top: 20px;
}

.button_login {
    display: flex;
    justify-content: center;
    align-items: center;
}

.login {
    padding: 7.6px 0;
    width: 50%;
    background-color: #252932;
    border: 1px solid #252932;
    color: #fff;
    text-decoration: none;
    text-align: center;
    text-decoration: none;
    font-size: .7rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 18.1px;
    margin-left: 3px;
} 

.login:hover{
    color: #252932;
    background-color: #fff;
    border: 1px solid #252932;
}


@media screen and (max-width:906px) {
    .container {
        padding-top: 10px;
    }
}