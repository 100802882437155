.navbar {
	width:100%; /* Establecemos que el header abarque el 100% del documento */
	overflow:hidden; /* Eliminamos errores de float */
	background:#252932;
	/*margin-bottom:100px;*/
	/*line-height: 50px;*/
	/*.position: fixed;*/
}

.nav {
	width:70%;
	/*max-width:1000px;*/
	/*margin:20px auto;*/
	background:#252932;
	float: right;
}

.wrapper {
	width:90%; /* Establecemos que el ancho sera del 90% */
	/*max-width:1000px; /* Aqui le decimos que el ancho máximo sera de 1000px */
	margin:auto; /* Centramos los elementos */
	/*overflow:hidden; /* Eliminamos errores de float */
}

.logo {
	width:25%;
	background:#252932;
	padding-top: 10px;
	padding-bottom: 10px;
	float: left;
}

.logo_a{
	color:#fff;
	text-align: center;
	font-weight: bold;
	font-size:30px;
	font-family: Arial, Helvetica, sans-serif;
	display:block;
	text-decoration:none;
}

.logo_span{
	display: none;
}


.nav_ul {
	overflow:hidden;
	list-style:none;
	margin: auto;
}

.li {
	float:left;
	margin: auto;
}

.navbar nav ul li a {
	color:#fff;
	padding:20px;
	display:block;
	font-family: Arial, Helvetica, sans-serif;
	text-decoration:none;
}

.nav_ul .icon {
	display: none;
}

.nav_ul .icon .icon_item{
	margin: auto;
}


.navbar nav ul li span {
	margin-right:10px;
}

.navbar nav ul li a:hover {
	background:#f56f3a;
	border-left: #f56f3a 10px solid;
		border-right: #f56f3a 10px solid;
}

section {
	padding:20px;
}

@media screen and (max-width:906px ) {
	.nav {
		width:100%;
		/*height:100%;
		left:-100%;*/
		
		margin:0;
		padding: 0;
		/*position: fixed;*/
	}

	.nav_ul {
		width: 100%;
		overflow:hidden;
		list-style:none;
		margin: auto;
	}

	.nav_ul .nav_container{
		display: flex;
	}

	.li {
		float:left;
	}

	

	.nav_ul .li_a{
		display: none;
	}

	.nav_ul .icon {
		margin: auto;
		padding-left: 65px;
		padding-right: 65px;
		display: block;
		font-size:30px;
	}

	.logo {
		display:none;
	}

	.bt_menu {
		display:none;
	}
}

@media screen and (max-width:756px ) {
	.nav_ul .icon {
		margin: auto;
		padding-left: 55px;
		padding-right: 55px;
		display: block;
		font-size:30px;
	}
	
	
}

@media screen and (max-width:667px ) {
	.nav_ul .icon {
		margin: auto;
		padding-left: 45px;
		padding-right: 45px;
		display: block;
		font-size:30px;
	}
	
}

@media screen and (max-width:578px ) {
	.nav_ul .icon {
		margin: auto;
		padding-left: 35px;
		padding-right: 35px;
		display: block;
		font-size:30px;
	}
	
}

@media screen and (max-width:489px ) {
	.nav_ul .icon {
		margin: auto;
		padding-left: 25px;
		padding-right: 25px;
		display: block;
		font-size:30px;
	}
	
}

@media screen and (max-width:400px ) {
	.nav_ul .icon {
		margin: auto;
		padding-left: 15px;
		padding-right: 15px;
		display: block;
		font-size:30px;
	}
	
}