.container {
    margin: 0;
    padding: 0;
    background-color: #FFFFFA;
    font-family: Manrope;
    min-height: 70vh;
    align-items: center;
    padding-top: 0px;
    padding-left: 0rem;
    padding-right: 0rem;
    text-align: center;
}

.height_img {
    height: 450px;
    overflow: hidden;
    margin-bottom: 10px;
    /*border-bottom: 1px solid #000;*/
}

.img {
    width: 100%;
}

.h4 {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 900;
    line-height: 3rem;
    font-family: Manrope, Helvetica, Arial;
    margin-bottom: 10px;
}

.p {
    text-align: center;
    font-size: 1.1rem;
    line-height: 1.7rem;
    margin-bottom: 0px;
    margin-left: 15px;
    margin-right: 15px;
    font-weight: 600;
}

.p_bold {
    font-size: 18px;
    font-weight: bold; 
}

.info{
    margin-top: 20px;
    margin-bottom: 20px;
}

.border{
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.border_l{
    border-left: 0px;
}

.border_r{
    border-right: 0px;
}

.col_circle {
    border-radius: 1.75rem;
    background-color: #298A08;
    color: #fff;
    font-weight: bold;
}

@media screen and (max-width:906px) {
    .height_img {
        height: 450px;
    }
}

@media screen and (max-width:756px) {
    .height_img {
        height: 450px;
    }
}

@media screen and (max-width:667px) {
    .height_img {
        height: 450px;
    }
}

@media screen and (max-width:578px) {
    .height_img {
        height: 400px;
    }
}

@media screen and (max-width:489px) {
    .height_img {
        height: 350px;
    }
}

@media screen and (max-width:380px) {
    .height_img {
        height: 300px;
    }
}

@media screen and (max-width:320px) {
    .height_img {
        height: 250px;
    }
}

@media screen and (max-width:250px) {
    .height_img {
        height: 200px;
    }
}

.button_cancel{
    background: #f56f3a;
    border: #f56f3a 1px solid;
    color: #fff  ;
    border-radius: 50px;
}

.button_cancel:hover{
    background: #252932;
    color: #fff ;
}
