.cardsection {
    color: #fff;
    font-family: Manrope, Helvetica, Arial;
    display: grid;
    gap: 1.75rem;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}

.card {
    /*background: #252932;*/
    padding: 0;
    border-radius: 5px;
    flex-wrap: wrap;
    margin: 0px;
}

.card_cont {
    padding: 15px 15px;
    max-height: 250px;
    min-height: 250px;
}

.cardheader {
    flex-basis: 100%;
    font-size: 1rem;
    text-transform: uppercase;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: bold;
}

.cardbody {
    flex-basis: 100%;
    margin-top: 1.2rem;
}

.overflow {
    max-height: 115px;
    min-height: 115px;
    overflow: hidden;
}

.cardbody p {
    margin: 10px;
}

.cardfooter {
    flex-basis: 100%;
    align-self: flex-end;
    font-weight: bold;
    font-size: 15px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}

.cardfooter_off {
    text-align: left;
    padding-left: 10px;
}

.cardfooter_sts {
    text-align: right;
    padding-right: 10px;
}

@media screen and (max-width:435px) {
    .cardsection {
        display: grid;
        gap: .5rem;
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
        padding: 5px;
    }
    .container {
        padding-top: 0px;
    }
    .card_cont {
        padding: 10px 10px;
        max-height: 180px;
        min-height: 180px;
    }
}

@media screen and (max-width:300px) {
    .cardsection {
        display: grid;
        gap: .5rem;
        grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
        padding: 5px;
    }
}